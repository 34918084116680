const gSelector = {
	html: document.documentElement,
	body: document.body,
	header: document.querySelector('.l-header'),
	headerOpenClass: 'is-navi-open',
};
const breakPoint = {
	sp: parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--breakPointSp')),
	wide: parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--breakPointWide'))
};
const windowSize = {
	width: window.innerWidth,
	height: window.innerHeight
};


// PC/SPでクリックイベントを出し分け

const clickOrTouch = () => {
	const isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
	return (isTouchDevice) ? 'touchend' : 'click';
}
const eventClickOrTouch = clickOrTouch();


// ユーザーエージェント関連

const deviceCategory = {
	mobile: "ua_mobile",
	tablet: "ua_tablet",
	pc: "ua_pc"
};
const deviceParser = () => {
	const ua = navigator.userAgent;
	if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
		// スマホ
		gSelector.html.classList.add('ua_mobile');
		return deviceCategory.mobile;
	} else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
		// タブレット
		gSelector.html.classList.add('ua_tablet');
		document.querySelector('meta[name=viewport]').setAttribute('content', 'width=1300,user-scalable=yes,maximum-scale=2.0');
		return deviceCategory.tablet;
	} else{
		// PC
		gSelector.html.classList.add('ua_pc');
		return deviceCategory.pc;
	}
};
const device = deviceParser();
// example
// if(device == deviceCategory.mobile) console.log("mobile");


// タブレットviewport設定

if(device == deviceCategory.tablet){
	const viewport = document.querySelector('meta[name="viewport"]');
	viewport.setAttribute("content","width=device-1400,initial-scale=1.0,maximum-scale=1.0");
}

// ウィンドウサイズをcss変数に代入

const windowSizeProperty = (() => {
	const windowSizeSetting = () => {
		document.documentElement.style.setProperty('--vh', (window.innerHeight) + 'px');
		document.documentElement.style.setProperty('--vw', (window.innerWidth) + 'px');
	};
	let timeoutID = 0;
	let delay = 500;
	windowSizeSetting();
	if(device == deviceCategory.pc || device == deviceCategory.tablet) {
		window.addEventListener("resize", () => {
			clearTimeout(timeoutID);
			timeoutID = setTimeout(()=>{
				windowSizeSetting();
			}, delay);
		}, false);
	}
})();

// youtubeAPI読み込み

const youtubeApiScript = (() => {
	var tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
})();


// アンカースクロール処理

class AnchorScroll {
	constructor(target) {
		const rect = target.getBoundingClientRect().top;
		const offset = window.pageYOffset;
		const gap = gSelector.header.offsetHeight + parseInt(getComputedStyle(gSelector.header).top);
		const position = rect + offset - gap;
		window.scrollTo({
			top: position,
			behavior: 'smooth',
		});
	}
}


// ヘッダーメニュー開閉

class HeaderMenu {
	constructor() {
		gSelector.body.classList.toggle(gSelector.headerOpenClass);
	}
}


// スクロール監視

class ScrollObserver {
	constructor(props) {
		const $this = this;
		$this.target = props.target; //対象の要素
		$this.options = props.options; // IntersectionObserverのオプション
		$this.activeClass = props.activeClass; // 付与するクラス名
		$this.observeEnd = props.observeEnd; // 要素が見えなくなった時にクラスを削除するか
		if(!$this.target) return;
		const array = Array.prototype.slice.call(document.querySelectorAll($this.target));
		const observer = new IntersectionObserver(ObserverCallback, $this.options)
		array.forEach((tgt) => {
			observer.observe(tgt)
		});
		function ObserverCallback(entries) {
			entries.forEach(function(entry, i) {
				const t = entry.target;
				if (entry.isIntersecting && !t.classList.contains($this.activeClass)) {
					t.classList.add($this.activeClass);
				} else {
					if($this.observeEnd == true)
					t.classList.remove($this.activeClass);
				}
			});
		};
	}
}
const primaryScrollObserver = new ScrollObserver({
	target:'.js-intersection',
	activeClass:'is-intersect',
	options:{
		root: null,
		rootMargin: "-30% 0px",
		threshold: 0
	}
});


// ロード後クラス追加

window.addEventListener('load', () => {
	gSelector.body.classList.add('is-loaded');
});


// アンカーリンク

const anchorLink = (() => {
	const scrollTrigger = document.querySelectorAll('a[href^="#"]');
	for (let i = 0; i < scrollTrigger.length; i++){
		scrollTrigger[i].addEventListener(eventClickOrTouch, (e) => {
			e.preventDefault();
			let href = scrollTrigger[i].getAttribute('href');
			let targetElement = document.getElementById(href.replace('#', ''));
			const defaultAnchor = new AnchorScroll(targetElement);
		});
	}
	// ページをまたぐアンカーリンク
	const anchorTrigger = document.querySelectorAll('a[data-anchor]');
	for (let i = 0; i < anchorTrigger.length; i++){
		anchorTrigger[i].addEventListener(eventClickOrTouch, (e) => {
			e.preventDefault();
			const data = anchorTrigger[i].getAttribute('data-anchor');
			const anchor = data.split('?');
			const anchorPath = anchor[0];
			const anchorId = anchor[1].split('=')[1];
			const anchorTarget = document.getElementById(anchorId);
			if(anchorPath == location.pathname) {
				// ページが同じならスクロール
				const pageAnchor = new AnchorScroll(anchorTarget);
				// URLに挿入する
				history.replaceState('','',data);
				// メニュー閉じる
				const anchorHeaderClose = new HeaderMenu;
			} else {
				// ページが異なるなら該当ページへ移動させる
				location.href = data;
			}
		});
	}
	// アクセス時にanchorが存在したらスクロールさせる
	window.addEventListener('load', () => {
		if(document.URL.match(/anchor/)) {
			const data = location.href;
			const anchor = data.split('?');
			const anchorId = anchor[1].split('=')[1];
			const anchorTarget = document.getElementById(anchorId);
			const gap = gSelector.header.offsetHeight;
			const loadAnchor = new AnchorScroll(anchorTarget);
		}
	});
})();


// トップページ
// youtube
const onYouTubeIframeAPIReady = () => {
	let ytPlayer;
	ytPlayer = new YT.Player('top-mv__movie',{
		videoId: 'BujaFDDNOF8', 
		playerVars: {
			autoplay: 1,
			playsinline: 1, 
			controls: 1, 
			showinfo: 0, 
			modestbranding: 1, 
			iv_load_policy: 3,
			fs: 1,
			rel: 0 
		},
		events: {
			'onReady': onPlayerReady,
			'onStateChange': onPlayerStateChange
		}
	});
	function onPlayerReady(event) {
		event.target.mute();
		event.target.playVideo();
	}
	function onPlayerStateChange(event) {
		if (event.data == YT.PlayerState.ENDED) {
			event.target.mute();
			event.target.playVideo();
		}
	}	
}
// 学校紹介 スライダー
const topIntroImage = (() => {
	const sliderElement = document.querySelector('.top-intro__image');
	if(!sliderElement) return;
	const slider = new Splide(sliderElement, {
		arrows:false,
		gap:'30rem',
		classes: {
			page : 'm-slider-pagination__item',
		},	
		breakpoints: {
			767: {
				gap:'18rem',
			},
		}
	}).mount();
	// リンクhover時にスライダーを切り替え
	const changeSliderOnHover = (() => {
		const menuItems = document.querySelectorAll('.top-intro__menu__item');
		if(window.matchMedia('(any-hover:none)').matches) return;
		menuItems.forEach((item, index) => {
			item.addEventListener('mouseenter', () => slider.go(index));
		});	
	})();
})();
// 魅力 スライダー
const topAttractionImageSet = (() => {
	const topAttractionImage = () => {
		if(!document.querySelector('.top-attraction__menu')) return;
		let slider;
		if (windowSize.width <= breakPoint.sp) {
			if (slider) {
				return;
			} else {
				const slider = new Splide( '.top-attraction__menu',{
					type: 'loop',
					classes: {
						page : 'm-slider-pagination__item',
						arrow : 'm-slider-arrow',
						prev  : 'm-slider-arrow--prev',
						next  : 'm-slider-arrow--next',		
					},		
				}).mount();
			}
		} else {
			if (slider) {
				slider.destroy();
				slider = undefined;
			}
		}
	}
	window.addEventListener('DOMContentLoaded', (event) => {
		topAttractionImage();
	});
	// 横のリサイズ時のみ処理
	let winCurrentWidth = window.innerWidth;
	window.addEventListener("resize", function() {
		if(device == deviceCategory.mobile) return;
		if (winCurrentWidth == window.innerWidth) return;
		winCurrentWidth = window.innerWidth;
		topAttractionImage();
	});	
})();	
// 学校生活 スライダー
const topSchoollifeImageSet = (() => {
	const topSchoollifeImage = () => {
		if(!document.querySelector('.top-schoollife__panels')) return;
		let slider;
		if (windowSize.width <= breakPoint.sp) {
			if (slider) {
				return;
			} else {
				const slider = new Splide( '.top-schoollife__panels',{
					type: 'loop',
					classes: {
						page : 'm-slider-pagination__item',
						arrow : 'm-slider-arrow',
						prev  : 'm-slider-arrow--prev',
						next  : 'm-slider-arrow--next',		
					},		
				}).mount();
			}
		} else {
			if (slider) {
				slider.destroy();
				slider = undefined;
			}
		}	
	}
	topSchoollifeImage();
	// 横のリサイズ時のみ処理
	let winCurrentWidth = window.innerWidth;
	window.addEventListener("resize", function() {
		if(device == deviceCategory.mobile) return;
		if (winCurrentWidth == window.innerWidth) return;
		winCurrentWidth = window.innerWidth;
		topSchoollifeImage();
	});	
})();	


// ヘッダーメニュー開閉
const headerHumberger = (() => {
	const target = document.querySelector('.l-header__humberger');
	if(!target) return;
	target.addEventListener(eventClickOrTouch, () => {
		const toggle = new HeaderMenu;
	}, false);
})();


// footer
// メニュー開閉
const footerMenu = (() => {
	if (window.matchMedia("(min-width:"+ breakPoint.wide +"px)").matches) return;
	const trigger = document.querySelectorAll('.l-footer__menu__item--has-child .l-footer__menu__link');
	trigger.forEach((target,index) => {
		target.addEventListener(eventClickOrTouch, (e) => {
			e.preventDefault();
			const wrap = target.closest(".l-footer__menu__item");
			wrap.classList.toggle('is-menu-open');
		});
	})
})();


// トップ お知らせ切り替え
const topNewsCategory = (() => {
	const list = document.querySelectorAll('.top-news__list[data-category]');
	const currentClass = 'is-current';
	// タブ切り替え
	const tabs = document.querySelectorAll('.top-news__tabmenu__item');
	for(let i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener(eventClickOrTouch, (tabsElement) => {
			// タブ
			document.querySelectorAll('.top-news__tabmenu__item.'+currentClass)[0].classList.remove(currentClass);
			tabsElement.currentTarget.classList.add(currentClass);
			const currentCategory = tabsElement.currentTarget.getAttribute('data-category');
			// コンテンツ
			document.querySelectorAll('.top-news__list.'+currentClass)[0].classList.remove(currentClass);
			const currentList = document.querySelector('.top-news__list[data-category="' + currentCategory + '"]');
			currentList.classList.add(currentClass);
		});		
	}
	// select切り替え
	const select = document.querySelector('.top-news__pulldown__select');
	if(!select) return;
	select.addEventListener('change', (selectElement) => {
		const currentCategory = select.value;
		document.querySelectorAll('.top-news__list.'+currentClass)[0].classList.remove(currentClass);
		const currentList = document.querySelector('.top-news__list[data-category="' + currentCategory + '"]');
		currentList.classList.add(currentClass);	
	});
})();


// フォーム関連
(function(){	
	// 同意チェック
	const agreeCheck = () => {
		const agreeInput = document.querySelector('.js-agree-check');
		const agreeButton = document.querySelector('.js-agree-button');
		const agreeButtonClass = 'm-action-button';
		const agreeDisbiled = 'm-action-button--disbiled';
		if(!agreeInput && !agreeButton) return;
		if(!agreeButton.classList.contains(agreeButtonClass)) return;
		// クラス付与処理
		const agreeClassSet = () => {
			agreeInput.checked ? agreeButton.classList.remove(agreeDisbiled) : agreeButton.classList.add(agreeDisbiled);
		}
		// 表示時
		agreeClassSet();
		// inputクリック時
		agreeInput.addEventListener('click', (e) => {
			agreeClassSet();
		});
	}
	agreeCheck();
})();


// wpcf7 入力エラー時に上部までスクロール
const wpcf7InvalidScroll = (() => {
	const form = document.querySelector('.wpcf7-form');
	if(!form) return;
	const submitButton = form.querySelector('.wpcf7-submit');
	submitButton.addEventListener("click", (e) => {
		setTimeout(() => { // wpcf7の処理後に発火させるため遅らせる
			if(!form.classList.contains('invalid')) return;
			const errorTip = form.querySelector('.wpcf7-not-valid-tip');
			const errorWrap = errorTip.closest('.contact-form__table__item');
			const errorPos = errorWrap.getBoundingClientRect();
			const errorPosY = window.scrollY  + errorPos.top - gSelector.header.offsetHeight;
			window.scrollTo({
				top: errorPosY,
				behavior: 'smooth',
			});
		}, "800")		
	});
})();


// selectでURL切り替え
const selectChangeUrl = (() => {
	const select = document.querySelectorAll('.js-select-url');
	select.forEach((tgt) => {
		tgt.addEventListener('change', () => {
			document.location.href = tgt.options[tgt.selectedIndex].value;
		});
	});
})();


// select中央寄せするため代替ラベルにテキスト挿入
const selectLabelSet = (select,label) => {
	if(!$(select).length || !$(label).length) return;
	$(select).change(function () {
		let select_option = $(this).find('option:selected');
		$(this).prev(label).text(select_option.text());
		$(this).blur();
	});
}
// TOP
selectLabelSet(
	'.top-news__pulldown__select',
	'.top-news__pulldown__label'
)
// お知らせ
selectLabelSet(
	'.m-article-filter__select',
	'.m-article-filter__label'
)


$(function(){
	if($('.mySwiper').length){
		new Swiper(".mySwiper", {
			autoplay: false,
			speed: 500,
			slidesPerView: 1,
			spaceBetween: 17,
			loop: false,
			navigation: {
				nextEl: ".next",
				prevEl: ".prev",
			},
			pagination: {
				el: ".swiper-dots",
				clickable: true
			},
			breakpoints: {
				767: {
					spaceBetween: 0,
				},
			},
		});
	}
	if($('.jsSwiper').length && window.innerWidth < 768){
		new Swiper(".jsSwiper", {
			autoplay: false,
			speed: 500,
			slidesPerView: 1,
			spaceBetween: 0,
			loop: false,
			navigation: {
				nextEl: ".next",
				prevEl: ".prev",
			},
			pagination: {
				el: ".swiper-dots",
				clickable: true
			},
			loop: true
		});
	}
	if($('.m-arrow-box').length){
		var ml = 41;
		$(window).resize(function(){
			if(window.innerWidth < 768){
				ml = 37;
			}
			var dotsW = $('.swiper-dots').innerWidth();
			var winw = ($('.m-arrow-box').width() - dotsW)/2 - ml;
			$('.m-arrow-box .prev').css('left',winw);
			$('.m-arrow-box .next').css('right',winw);
		}).trigger('resize');
	}
	if($('.page-campuslife-results .campuslife-results_sec01').length && window.innerWidth < 768){
		$(".page-campuslife-results .campuslife-results_sec01 .m-headline03").click(function(){
			$(this).toggleClass("on").next().stop().slideToggle(300);
		});
	}
	if($('.car-swpier').length){
		new Swiper(".car-swpier", {
			autoplay: true,
			speed: 500,
			slidesPerView: 1.28,
			spaceBetween: 16,
			pagination: {
				el: ".swiper-dots",
				clickable: true
			},
			loop: true,
			breakpoints: {
				767: {
					spaceBetween: 30,
				},
			},
		});
	}
	if($('[data-fancybox]').length){
		$('[data-fancybox]').fancybox({
			touch: false,
			backFocus: false,
			autoFocus: false,
			toolbar  : false,
			smallBtn : true,
			mobile: {
				clickSlide: 'close',
			}
		});
	}
	
})